var scrollDown = lottie.loadAnimation({
	container: document.getElementById('scrolldown'),
	renderer: 'svg',
	loop: true,
	autoplay: true,
	path: '/json/Scroll_down.json'
});

var animation2 = lottie.loadAnimation({
	container: document.getElementById('icon-sound'),
	renderer: 'svg',
	loop: false,
	autoplay: true,
	path: '/json/Sound_on.json'
});
